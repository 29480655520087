.sidebar {
  height: 100vh;
  overflow: scroll;
}

.sidebar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.vertical-line {
  height: 40px;
  border-left: 1px solid #ccc;
  margin: 0 10px;
}

.admin {
  margin: 9px !important;
  margin-top: 5px;
}

.clicked:hover {
  cursor: pointer;
}

.notification {
  transform: translate3d(988px, 64px, 0px) !important;
  // text-align: center !important;
}

.notification_display{
p{
  margin: 0;
}
}

.custom-logo-size {
  font-size: 1.65rem;
}

// .custom-menu .ant-menu-item-selected {
//   background-color: #23B8CB !important;  // Change the background color
//   border-left: 3px solid #23B8CB !important;  // Optional: Adjust the left border color
// }

// .custom-menu .ant-menu-item-selected a {
//   color: white !important;  // Ensure the text color is visible
// }

.ant-menu-item-selected{
  background-color: #F9B023 !important; 
  border-left: 3px solid #F9B023 !important;
}
