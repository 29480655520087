/** @format */

.custom-header {
	font-size: 16px; /* Set the desired font size for header names */
}

.custom-cell {
	font-size: 14px; /* Set the desired font size for cell content (emails) */
}

.custom-image-cell img {
	width: 50px; /* Set the desired width for images */
	height: 50px; /* Set the desired height for images */
}

.tabledata {
	height: 800px;
	width: 100%;
	align-items: center;
	margin-left: 1.5%;
	margin-top: 3.5%;
	margin: initial;
	font-size: 80px;
	color: black;
	text-align: center;
}

.css-1raz6df {
	background-color: white;
	border-width: 1px;
	border-style: solid;
	border-color: rgba(224, 224, 224, 1);
	border-radius: 5px;
}

.p-card .p-card-body {
	padding: 0 !important;
}

.personal_informtaion_box {
	background: #f3f7f9;
	padding: 10px;
	margin-bottom: 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.personal_informtaion {
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 0.937rem;
  margin-bottom: 0px !important;
}

.heading_pi {
	text-transform: uppercase;
	color: darkgrey;
	font-weight: bold;
	font-size: inherit;
}

.card_two{
  padding: 7px;
  box-shadow: none !important;
  border: 1px solid #DEE2E6;
  border-radius: 10px !important;
  
}

.card_2_div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
}
