.view_all_questions_button:hover{
color: green;
cursor: pointer;
}


.add_question_button{
    padding: 10px !important;
    height: 39px !important;
    margin-top: 50px !important;
    margin-left: 30px !important;
}