.form {
    margin-left: 400px;
    margin-right: 400px;
    /* margin-top: 130px; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 40px;
    border-radius: 10px;
    font-size: medium;
  }
  
  .new-password-toggle-icon {
    position: absolute !important;
    top: 68%;
    left: 96%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1.2em;
    color: #6c757d;
  }
  
  .confirm-password-toggle-icon {
    position: absolute !important;
    left: 96%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1.2em;
    color: #6c757d;
    top: 68%;
  }

  .current-password-toggle-icon{
    position: absolute !important;
    left: 93%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1.2em;
    color: #6c757d;
    top: 33.5%;
  }
  