/* Error401.css */

.main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .error_image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .main_box {
    text-align: center;
  }
  
  .error_heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .text-muted {
    margin: 0;
  }
  
  .back_button {
    margin-top: 20px;
  }
  