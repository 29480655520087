.setting_sidebar_container {
  border-width: 2px;
  border-style: solid;
  border-color: rgba(224, 224, 224, 1);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  /* flex-direction: column; */
}
.setting_left_sidebar {
  border-right: solid 2px rgba(224, 224, 224, 1);
  width: 24%;
  height: 600px;
  /* flex: 1; */

}
.setting_right_container {
    flex: 2;

    /* overflow: auto;
    flexGrow: 1  */
  }

.css-cveggr-MuiListItemIcon-root {
    min-width: 43px !important;
}

/* .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected{
    border-right-width: 2px;
    border-right-style: solid;
    border-right-color: purple;
} */

