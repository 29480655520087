/** @format */

.left_container {
	border: 1px solid lightgray;
	border-radius: 5px;
	margin-right: 20px;
	padding: 20px;
	width: 30%;
}

.right_container {
	border: 1px solid lightgray;
	border-radius: 5px;
	padding: 20px;
	width: 70%;
}

.buttons_divison {
	margin-top: 40px;
	position: absolute;
	left: 84%;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
	.ant-upload.ant-upload-select {
	height: 210px !important;
	width: 290px !important;
	padding: 10px !important;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
	.ant-upload-list.ant-upload-list-picture-card
	.ant-upload-list-item-container {
	height: 210px !important;
	width: 290px !important;
	padding: 10px !important;
}

.save_button {
	width: 200px;
	background: #a4a9fc;
	font-size: 14px;
	font-weight: bold;
	padding: 10px;
}
.save_lecture_button {
	width: 120px;
	background: #a4a9fc;
	font-size: 14px;
	font-weight: bold;
	padding: 10px;
}

.cancel_button {
	width: 100px;
	color: white;
	font-size: 14px;
	font-weight: bold;
	padding: 5px;
}

.lecture-form-buttons {
	margin-top: 20px;
	margin-left: 400px;
}

.save_button:hover {
	background: #7d82e6 !important;
}

.add_section_button {
	width: 200px;
	background: #a4a9fc;
	font-size: 14px;
	font-weight: bold;
	padding: 10px;
}

.save_section_button {
	width: 100px;
	background: #a4a9fc;
	font-size: 14px;
	font-weight: bold;
	padding: 10px;
}

.add_section_button:hover {
	background: pink;
}

.main_div_section {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 50vh;
	margin-top: 20px;
}

.modal-header {
	border-bottom: none !important;
}

.modal-footer {
	border-top: none !important;
}

//lecture
.CourseLearn {
	margin: 20px 0px;

	h3 {
		font-weight: bold;
	}

	.width_full {
		width: 100%;
	}

	.start {
		font-weight: bold;
		background-color: #f9b531;
		height: fit-content;
		padding: 2px 10px;
		border-radius: 5px;
		cursor: pointer;
	}

	.color {
		color: #f9b531;
		font-weight: bold;
	}

	.section_name {
		display: flex;
		justify-content: space-between;

		h5 {
			font-size: medium;
		}

		.marked_green {
			color: #37ab57;
		}
	}

	.main {
		display: flex;
		gap: 20px;

		.List_scroll {
			height: 75dvh;
			overflow-x: hidden !important;
			overflow: scroll;
		}

		.List_scroll::-webkit-scrollbar {
			display: none;
		}

		.course_list {
			width: 40%;

			.accordion-item {
				// margin: 10px 0px;
				background-color: #f7f6f4 !important;
				// padding: 10px;
				border: none !important;
				border-radius: 10px;
				margin: 10px 0px;

				h6 {
					font-weight: 700;
				}
			}

			.accordion-body {
				padding: 0 !important;
			}

			.accordion-button {
				// padding: 0px 10px !important;
				// border: 1px solid gray;
				box-shadow: none !important;
				border: none !important;
				border-radius: 8px !important;
				background-color: #f7f6f4 !important;
			}

			.list-group-item {
				background-color: #f7f6f4 !important;
			}
		}
	}
}
.no-lecture-container {
	width: 650px;
	background: #f7f6f4;
	margin-top: 51px;
	height: 256px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px !important;
	margin-bottom: 20px;
}

.no-lecture-container p {
	text-align: center;
	font-weight: bold;
	font-size: large;
}

.lecture-form-container {
	border-radius: 8px !important;
	background: #f7f6f4;
	// height: 250px;
	padding: 25px;
}
.button-group {
	margin-top: 51px;
	margin-bottom: 20px;
}

.group_button {
	border-radius: none !important;
	margin-right: 10px !important;
}

.group_button:hover {
	border-radius: none !important;
	margin-right: 10px;
	background-color: black !important;
	color: white !important;
}

.add_lecture_button:hover{
	background-color: lightgray !important;
	border:none !important
}
